import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image";

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';

const IndexPage = ({ data }) => {
  const products = data.shopifyCollection.products
  const collections = data.allShopifyCollection.nodes
  const articles = data.allShopifyArticle.nodes
  
  const items = [
    ...products.map(product => ({
      type: 'product',
      original: product.images[0].localFile.childImageSharp.fluid.src,
      srcSet: product.images[0].localFile.childImageSharp.fluid.srcSet,
      description: <div>{product.title}</div>,
      handle: product.handle
    })),
    ...collections.map(collection => ({
      type: 'collection',
      original: collection.image?.localFile.childImageSharp.fluid.src,
      srcSet: collection.image?.localFile.childImageSharp.fluid.srcSet,
      description: <div>{collection.title}</div>,
      handle: collection.handle
    })),
    ...articles.map(article => ({
      type: 'article',
      original: article.image?.localFile.childImageSharp.fluid.src,
      srcSet: article.image?.localFile.childImageSharp.fluid.srcSet,
      description: <div>{article.title}</div>,
      handle: `blog/${article.url.split('/').pop()}`
    }))
  ]
  
  items.forEach(item => {
    if (item.handle === 'du-ciel-a-la-terre-fall-winter-21') {
      item.description = <div><div>Du Ciel À La Terre</div><div>Fall/Winter ʼ21</div></div>
    }
  })

  const desiredItems = data.shopifyCollection.description?.split(',') || items.filter(item => item.type === 'product').map(product => product.handle)

  const galleryItems = items
    .filter(item => desiredItems.includes(item.handle))
    .sort((a, b) => desiredItems.indexOf(a.handle) - desiredItems.indexOf(b.handle))

  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  const galleryRef = useRef(null)
  const [ renderItemOriginal, setRenderItemOriginal ] = useState()
  
  useEffect(() => {
    setRenderItemOriginal(() => galleryRef.current.renderItem)
  }, [ galleryRef ])

  return (
    <Layout stretch={true}>
      <SEO title="Ateliér Fuego &amp; Flores" />
      <div css={css`
        .image-gallery-image, .image-gallery {
          margin-bottom: 6rem;
          max-height: calc(100vh - 280px) !important;
        }
        .image-gallery-description {
          display: block;
          height: 6rem;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          & > * {
            position: relative;
            top: calc(50% + 3px);
            transform: translateY(-50%);
          }

          background: none;
          color: #000;
          font-size: 2rem !important;
          white-space: nowrap;
          padding: 0;
        }

        .image-gallery {
          .image-gallery-bullets {
            position: static;

            .image-gallery-bullet {
              appearance: none;
              background-color: #797979;
              border: none;
              border-radius: 999px;
              box-shadow: none;
              display: inline-block;
              margin: 0 5px;
              outline: none;
              padding: 0;
              width: 10px;
              height: 10px;
              transition: all .2s ease-out;

              position: relative;
              vertical-align: top;

              &.active {
                transform: none;
                background-color: #000;

                &:before {
                  content: '';
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
                  border: 1px solid #000;
                }
              }
            }
          }
        }
      `}>
        <ImageGallery
          items={galleryItems}
          ref={galleryRef}
          renderItem={renderItemOriginal ? (item) => {
            const content = renderItemOriginal(item)
            return (
              <Link to={'/'+item.handle} style={{display:'block'}}>{content}</Link>
            )
          } : null}
          slideInterval={5000}
          showThumbnails={false}
          showNav={false}
          showPlayButton={false}
          showFullscreenButton={false}
          showBullets={true}
          autoPlay={true}
        />
      </div>

      {/* {data.shopifyCollection.products.map( (product) => (
        <div
          key={product.id}
          css={css`
            & + * {
              margin-top: 24px;
            }
          `}
        >
          <Link to={product.handle}>
            <Img
              fluid={product.images[0].localFile.childImageSharp.fluid}
            />
          </Link>
        </div>
      ))} */}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    shopifyCollection(title: {eq: "Home page (Store)"}) {
      description
      products {
        id
        title
        shopifyId
        images {
          localFile {
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        description
        handle
      }
    }
    allShopifyCollection {
      nodes {
        id
        title
        handle
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allShopifyArticle {
      nodes {
        id
        url
        title
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`